import { ChangeEvent, useState } from "react"
import styled from "styled-components"

import { TextField } from "@material-ui/core"

import { passwordSignin } from "src/context/auth/signIn"
import { useAppData } from "src/context/useAppData"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { MButtonLegacy } from "src/ui/Button/MButtonLegacy"
import { MBanner } from "src/ui/MBanner/MBanner"
import { spacing } from "src/ui/spacing"

export function LoginForm({ prefillEmail }: { prefillEmail?: string }) {
  const { authState, loading } = useAppData()

  const [email, setEmail] = useState(prefillEmail ?? "")
  const [password, setPassword] = useState("")
  const [loggingIn, setLoggingIn] = useState(false)
  const [error, setError] = useState(false)

  const { t } = useTranslate()

  const handleSubmit = async (e: ChangeEvent<object>) => {
    e.preventDefault()

    setLoggingIn(true)
    passwordSignin({
      username: email,
      password,
      onSignedIn: ({ authorization }) =>
        authState.setAuthorization(authorization),
      onSettled: () => setLoggingIn(false),
      onError: () => setError(true),
    })
  }

  return (
    <FormBox onSubmit={handleSubmit}>
      <StyledTextField
        id="email"
        label={t(langKeys.email)}
        type="email"
        autoFocus
        fullWidth
        required
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        onBlur={(e) => setEmail(e.target.value.toLocaleLowerCase().trim())}
        disabled={!!prefillEmail}
      />

      <StyledTextField
        id="password"
        label={t(langKeys.password)}
        type="password"
        fullWidth
        required
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />

      {error && (
        <StyledAlert type="error" fullWidth>
          {t(langKeys.sign_in_failed)}
        </StyledAlert>
      )}

      <MButtonLegacy
        size="large"
        fullWidth
        type="submit"
        loading={loggingIn || loading}
      >
        {t(langKeys.sign_in)}
      </MButtonLegacy>
    </FormBox>
  )
}

const FormBox = styled.form`
  font-size: 0.875rem;
`

const StyledTextField = styled(TextField)`
  margin-bottom: ${spacing.L};
`

const StyledAlert = styled(MBanner)`
  margin-bottom: ${spacing.S};
`
